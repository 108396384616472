import { overlayLoading } from './overlayLoadingAction';
import { dispatchToast } from "./toastAction";
import { fetch } from "../../utils/dataAccess";

export const register = (user) => {
    return (dispatch) => {
     // dispatch(overlayLoading(true));
      return fetch("/api/register", {
        method: "POST",
        body: JSON.stringify({...user}),
      })
        .then((response) => response.json().then((retrieved) => ({ retrieved })))
        .then(({ retrieved }) => {
          dispatch(overlayLoading(false));
          const d = new Date();
          dispatch({
            type: "REGISTER_SUCCESS",
            payload: true,
          });
          dispatch(dispatchToast(["success", "User registered successfully.", {}]));
          //console.log("###retrieved", retrieved);
          /*
          if (rememberMe) {
            d.setTime(
              d.getTime() + Constants.NUMBER_REMEMBERED_DAYS * 24 * 60 * 60 * 1000
            );
            cookies.set("refresh_token", retrieved.refresh_token, {
              path: "/",
              expires: d,
            });
            cookies.set("rememberMe", 1, { path: "/", expires: d });
          } else {
            cookies.set("refresh_token", retrieved.refresh_token, { path: "/" });
          }
  
          const accessToken = retrieved.token;
          const tokenPayload = jwtDecode(accessToken);
          
          dispatch({
            type: "LOGIN_SUCCESS",
            payload: { accessToken, tokenPayload },
          });
          */
        })
        .catch((e) => {
          dispatch(overlayLoading(false));
          dispatch(dispatchToast(["error", e.message, {}]));
        });
    };
  };
  