import { fetchAuth, UnauthorizedError } from '../../../utils/dataAccess';
import { overlayLoading } from '../overlayLoadingAction';
import store from '../../store';
import { refreshToken } from '../refreshingToken';
import { makeCancelable } from '../abortAction';

export function error(error) {
    return { type: 'USER_SHOW_ERROR', error };
}

export function success(retrieved) {
    return { type: 'USER_SHOW_SUCCESS', retrieved };
}

export function retrieve() {
    return dispatch => {
        dispatch(reset());
        dispatch(overlayLoading(true));
        const signal = dispatch(makeCancelable('user'));
        const userId = store.getState().auth.TokenPayload.id;
        //if(userId !== "undefined")
        //return;
        return fetchAuth(`/api/users/${userId}`, { signal }).then(response =>
            response.json().then(retrieved => ({ retrieved })),
        ).then(({ retrieved }) => {
            dispatch(overlayLoading(false));
            dispatch(success(retrieved));
        }).catch(e => {
            dispatch(overlayLoading(false));
            if (e.code === 20) return; // abort occurred
            if (e instanceof UnauthorizedError) return dispatch(refreshToken());
            dispatch(error(e.message));
        });
    };
}

export function reset() {
    return dispatch => {
        dispatch({ type: 'USER_SHOW_RESET' });
    };
}
