import React, { Component } from "react";
import QrReader from "react-qr-scanner";
import { connect } from "react-redux";
import {
  Button,
  Container,
  Col,
  Row,
  Form,
  Card,
  InputGroup,
  Modal,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCancel,
  faArrowTurnRight,
  faBackward,
  faMoneyBill,
} from "@fortawesome/free-solid-svg-icons";
import { Link, Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import i18n from "../../i18n";
import { getTransaction } from "../redux/actions/getTransactionAction";
import { transactionSend } from "../redux/actions/transactionSend";
class QRCodeScan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      delay: 1,
      result: "",
      stream: null,
      error: null,
      amount: 0,
      scannerDelay: 10, // 1 minute in milliseconds
      sent: false,
    };

    this.handleScan = this.handleScan.bind(this);
  }

  componentDidMount() {
    // Set a timeout to update the scannerDelay state after 1 minute
    this.delayTimeout = setTimeout(() => {
      this.setState({ scannerDelay: 0 });
      this.stopCamera();
    }, this.state.scannerDelay);

    // Check if navigator.mediaDevices is available
    if (navigator.mediaDevices) {
      // Request access to the user's camera
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          // Permission granted, set the camera stream in state
          this.setState({
            stream: stream,
          });
        })
        .catch((error) => {
          // Permission denied or other error
          this.setState({
            error: error,
          });
        });
    } else {
      this.setState({
        error: new Error("getUserMedia is not supported in this browser."),
      });
    }



    // Cleanup function to stop the camera stream when the component unmounts
    return () => {
      if (this.state.stream) {
        this.state.stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }

  componentDidUpdate(prevProps, prevState) {

    // Check if specific conditions are met before doing something
    if (this.props.transaction !== prevProps.transaction) {
      this.state.amount = this.props.transaction.retrieved.amount;
    }
  }

  componentWillUnmount() {
    this.stopCamera();

    // Clear the timeout on component unmount to avoid memory leaks
    clearTimeout(this.delayTimeout);
  }

  stopCamera = () => {
    const { stream } = this.state;
    if (stream) {
      stream.getTracks().forEach((track) => {
        track.stop();
      });
    }
  };

  handleScan(data) {
    this.setState({
      result: data,
    });
    console.log(data);
    if (data) {
      this.props.clearUuid("");
      this.props.launchOverlay(true);
      this.props.getTransaction(data.text);
    }
  }

  handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "amount" && value > 0)
      this.setState({
        amount: value,
      });
  };

  handleError(err) {
    console.error(err);
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.launchOverlay(true);
    this.props.transactionSend(
      this.props.transaction.retrieved.uuid,
      this.state.amount
    );
    this.state.sent = true; //".clearUuid("");
  };

  render() {


    if (!this.props.isLoggedIn) {
      this.stopCamera();
      return <Navigate to={"/login"} />;
    } else if (this.state.result && this.state.result.text != null) {
      return (
        <div>
          <div className="content">
            <div className="container mt-5">
              <Row className="justify-content-md-center">
                <Row className="justify-content-center">
                  {this.props.transaction?.retrieved ? (
                    <Col className="mb-2">
                      <h2>Send Money</h2>
                      <Form className="mt-4" onSubmit={this.handleSubmit}>
                        <Form.Group id="amount" className="m-2">
                          <Form.Label>How much:</Form.Label>
                          <InputGroup className="m-2">
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faMoneyBill} />
                            </InputGroup.Text>
                            <Form.Control
                              className="mr-1"
                              name={"amount"}
                              type="number"
                              placeholder="Amount"
                              onChange={this.handleInputChange}
                              value={this.state.amount}
                            />
                            Fcfa
                          </InputGroup>

                          <InputGroup className="m-2">
                            <Form.Control
                              as="textarea"
                              rows={3}
                              name={"description"}
                              type="text"
                              placeholder="Description..."
                              value={this.props.transaction.retrieved.description}
                              disabled
                            />
                          </InputGroup>

                          <Button
                            variant="primary"
                            type="submit"
                            className="w-100 m-2"
                          >
                            Send
                          </Button>
                        </Form.Group>

                        <Link to="/">
              <Button variant="primary" size="sm">
                <FontAwesomeIcon icon={faBackward} className="me-1" />{" "}
                {i18n.t("back-to-home")}
              </Button>
            </Link>

                      </Form>
                    </Col>
                  ) : (
                    ""
                  )}

{/*                   <Link to="/">
                    <Button variant="primary" size="sm" className="me-2">
                      <FontAwesomeIcon icon={faCancel} className="me-1" />{" "}
                      Cancel
                    </Button>
                  </Link>

                  <Link to="/send">
                    <Button variant="secondary" size="sm">
                      <FontAwesomeIcon
                        icon={faArrowTurnRight}
                        className="me-1"
                      />{" "}
                      Send
                    </Button>
                  </Link> */}
                </Row>
              </Row>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="content">
          <div className="container mt-5">
            <Row className="justify-content-md-center">
              <Row className="justify-content-center">
                <Col className="justify-content-center">
                  <h3>Scan QR</h3>
                  <Row className="justify-content-center">
                    {this.props.isLoggedIn && !this.state.sent ? (
                      <QrReader
                        delay={this.state.scannerDelay}
                        onError={this.handleError}
                        onScan={this.handleScan}
                        style={{
                          width: "100%",
                          maxWidth: "400px",
                          marginTop: "20px",
                          border: "2px solid #ccc",
                        }}
                      />
                    ) : (
                      " "
                    )}
                  </Row>
                  <Row className="justify-content-center m-2">
                    <Link to={"/"}>
                      <Button>
                        <FontAwesomeIcon icon={faBackward} />
                        {i18n.t("back-to-home")}
                      </Button>
                    </Link>
                  </Row>
                </Col>
              </Row>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  ...state,
  isLoggedIn: state.auth.isLoggedIn,
  transaction: state.transaction.show,
  user: state.user.show,
});

QRCodeScan.propTypes = {
  user: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({
  getTransaction: (data) => dispatch(getTransaction(data)),
  transactionSend: (uuid, amount) => dispatch(transactionSend(uuid, amount)),
  launchOverlay: (value) =>
    dispatch({
      type: "OVERLAY_LOADING",
      payload: value,
    }),
  clearUuid: (value) =>
    dispatch({
      type: "TRANSACTION_UUID",
      payload: value,
    }),
  //reset: () => dispatch(reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(QRCodeScan);
