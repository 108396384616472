import React, { Component } from 'react';
import { Link } from "react-router-dom";
import shape2 from "../../assets/img/shape/shape2.svg";
import shape3 from "../../assets/img/shape/shape3.svg";
import shape4 from "../../assets/img/shape/shape4.png";
import shape5 from "../../assets/img/shape/shape5.png";
import shape7 from "../../assets/img/shape/shape7.png";
import shape8 from "../../assets/img/shape/shape8.png";
import shape9 from "../../assets/img/shape/shape9.png";
import shape10 from "../../assets/img/shape/shape10.png";


class PageHeader extends Component {
    render() {
        
        let { pageTitle, breadcrumbTextOne, breadcrumbTextTwo, breadcrumbUrl } = this.props;

        return (
            <React.Fragment>
                <div className="page-title-area page-title-bg2">
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                                <div className="page-title-content">
                                    <h2>{pageTitle}</h2>
                                    <ul>
                                        <li>
                                            <Link to={breadcrumbUrl}>
                                                <a>{breadcrumbTextOne}</a>
                                            </Link>
                                        </li>
                                        <li>{breadcrumbTextTwo}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Animation Shape Image */}
                    <div className="shape-img2">
                        <img src={shape2} alt="image" />
                    </div>
                    <div className="shape-img3">
                        <img src={shape3} alt="image" />
                    </div>
                    <div className="shape-img4">
                        <img src={shape4} alt="image" />
                    </div>
                    <div className="shape-img5">
                        <img src={shape5} alt="image" />
                    </div>
                    <div className="shape-img7">
                        <img src={shape7} alt="image" />
                    </div>
                    <div className="shape-img8">
                        <img src={shape8} alt="image" />
                    </div>
                    <div className="shape-img9">
                        <img src={shape9} alt="image" />
                    </div>
                    <div className="shape-img10">
                        <img src={shape10} alt="image" />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default PageHeader;