import React, { useState, useEffect } from "react";
import QRCodeCanvas from "qrcode.react";
import { useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  InputGroup,
  Modal,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward, faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import Profile1 from "../assets/img/logo.png";
import { transaction } from "../redux/actions/transactionAction";
import { connect } from "react-redux";
import i18n from "../../i18n";
import { saveCurrentPath } from "../redux/actions/refreshingToken";
import { useLocation } from "react-router-dom";

function MyVerticallyCenteredModal(props) {
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Scan & Pay</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <h6>QR Code:</h6>
        {props.formdata.uuid && (
          <div className="img-fluid ">
            <QRCodeCanvas
              value={props.formdata.uuid.toString()}
              imageSettings={props.imagesettings}
            />
          </div>
        )}
        {props.formdata.amount ? (
          <p>Amount: {props.formdata.amount} CFA</p>
        ) : (
          ""
        )}
        {props.formdata.description ? (
          <p>Description: {props.formdata.description}</p>
        ) : (
          ""
        )}
      </Modal.Body>
      <Modal.Footer>
        <p>{`This Transaction will expire in ${formatTime(
          props.countdown
        )} minutes.`}</p>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

const QRCodeGenerator = (props) => {
  const location = useLocation();
  const [formData, setFormData] = useState({
    uuid: "",
    amount: "",
    description: "",
    receiver: "",
    latitude: "",
    longitude: "",
  });
  const [modalShow, setModalShow] = React.useState(false);
  const [imageSettings, setImageSettings] = useState({
    src: Profile1,
    x: undefined,
    y: undefined,
    height: 24,
    width: 24,
    excavate: true,
  });
  const [countdown, setCountdown] = useState(2000);
  //
  useEffect(() => {

    if (props.user) {
      setFormData({
        ...formData,
        receiver: props.user.id,
      });
    }

    if (props.uuid) {
      setFormData({
        ...formData,
        uuid: props.uuid,
      });
      setModalShow(true);
    }
    let interval;
    if (modalShow) {
      // Start the countdown when the modal is opened
      interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    
    return () => {
      if (props.uuid) props.clearUuid("");
      props.saveCurrentPath(location.pathname);
    };
  }, [props.uuid, modalShow]);

  useEffect(() => {
    if (countdown == 0) {
      setModalShow(false);
      props.clearUuid("");
    }

    // Reset the countdown when the modal is closed
    if (!modalShow) {
      setCountdown(120); // You can set the initial countdown value here
    }
  }, [modalShow, countdown]);

  if (!props.isLoggedIn) {
    return <Navigate to="/login" />;
  }

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.clearUuid("");
    props.launchOverlay(true);
    props.transaction(formData);
  };
  return (
    <div>
      <div className="content">
        <div className="container mt-5">
          <Row className="justify-content-md-center">
            <Row className="justify-content-center">
              <Col className="mb-2">
                <h2>Transaction Qr Code</h2>
                <Form className="mt-4" onSubmit={handleSubmit}>
                  <Form.Group id="amount" className="m-2">
                    <Form.Label>How much:</Form.Label>
                    <InputGroup className="m-2">
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faMoneyBill} />
                      </InputGroup.Text>
                      <Form.Control
                        className="mr-1"
                        name={"amount"}
                        type="number"
                        placeholder="Amount"
                        onChange={handleInputChange}
                      />
                      Fcfa
                    </InputGroup>

                    <InputGroup className="m-2">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name={"description"}
                        type="text"
                        placeholder="Description..."
                        onChange={handleInputChange}
                      />
                    </InputGroup>

                    <Button
                      variant="primary"
                      type="submit"
                      className="w-100 m-2"
                    >
                      Generate QR
                    </Button>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Row>

          <MyVerticallyCenteredModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            formdata={formData}
            imagesettings={imageSettings}
            countdown={countdown}
          />
          <Col className="m-2">
            <Link to="/">
              <Button variant="primary" size="sm">
                <FontAwesomeIcon icon={faBackward} className="me-1" />{" "}
                {i18n.t("back-to-home")}
              </Button>
            </Link>
          </Col>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...state,
  isLoggedIn: state.auth.isLoggedIn,
  uuid: state.auth.uuid,
  user: state.user.show.retrieved
});

const mapDispatchToProps = (dispatch) => ({
  transaction: (data) => dispatch(transaction(data)),
  registerComplete: () =>
    dispatch({
      type: "REGISTER_SUCCESS",
      payload: false,
    }),
  launchOverlay: (value) =>
    dispatch({
      type: "OVERLAY_LOADING",
      payload: value,
    }),
  clearUuid: (value) =>
    dispatch({
      type: "TRANSACTION_UUID",
      payload: value,
    }),
  saveCurrentPath: (value) => dispatch(saveCurrentPath(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QRCodeGenerator);
