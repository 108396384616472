import React, { Component, Suspense } from "react";
import "./assets/styles/bootstrap.min.css";
import "../../src/app/assets/styles/flaticon.css";
import "../../src/app/assets/styles/fontawesome.min.css";



// Global CSS
import "./assets/styles/style.css";
import "./assets/styles/responsive.css";

//import "./App.scss";

import { refreshToken } from "./redux/actions/refreshingToken";
import { BrowserRouter as Router, Routes } from "react-router-dom";
import Header from "./components/layout/Header";
import routes from "./routes/routes";
import Footer from "./components/layout/Footer2";
import { logout } from "./redux/actions/auth";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import { withTranslation } from "react-i18next";
import Constants from "./constants/Constants";
import { connect } from "react-redux";
import { default as LoadingOverlay } from "react-loading-overlay-ts";
import { Spinner } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import GoTop from './components/layout/GoTop';

class LegacyComponentClass extends Component {
  static propTypes = {
    refreshToken: PropTypes.func,
    logout: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      enableCookies: false,
      isLoading: true,
    };
    this.events = ["load", "mousedown", "click", "scroll", "keypress"];

    this.warn = this.warn.bind(this);
    this.logout = this.logout.bind(this);
    this.resetTimeout = this.resetTimeout.bind(this);
    this.handleRefreshToken = this.handleRefreshToken.bind(this);
    this.handleOverlayClose = this.handleOverlayClose.bind(this);
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      const cookies = new Cookies();
      if (cookies.get("refresh_token")) this.handleRefreshToken();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.auth.isAuthenticated &&
      this.props.auth.isAuthenticated !== prevProps.auth.isAuthenticated
    ) {
      // console.log('isLoggedIn');
      //this.setTimeout();
      this.events.forEach((event) =>
        window.addEventListener(event, this.resetTimeout)
      );
    }
    if (
      !this.props.auth.isAuthenticated &&
      this.props.auth.isAuthenticated !== prevProps.auth.isAuthenticated
    ) {
      // console.log('NOT isLoggedIn');
      this.clearTimeout();
      this.events.forEach((event) =>
        window.removeEventListener(event, this.resetTimeout)
      );
    }
  }

  handleRefreshToken = () => {
    this.props.refreshToken();
  };

  clearTimeout() {
    // console.log('clear previous timeout');
    if (this.warnTimeout) clearTimeout(this.warnTimeout);
    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  }

  setTimeout() {
    const cookies = new Cookies();
    const rememberMe = cookies.get("rememberMe") || null;
    if (rememberMe !== null && parseInt(rememberMe) === 1) {
      // remember Me
    } else {
      // console.log('setTimeout');
      this.warnTimeout = setTimeout(
        this.warn,
        (Constants.LOGOUT_TIME_OUT - Constants.WARNING_TIME_OUT) * 100
      );
      this.logoutTimeout = setTimeout(
        this.logout,
        Constants.LOGOUT_TIME_OUT * 100
      );
    }
  }

  resetTimeout() {
    // console.log('resetTimeout');
    this.clearTimeout();
    this.setTimeout();
  }

  warn() {
    console.log(
      "You will be logged out automatically in " +
        Constants.WARNING_TIME_OUT +
        " seconds"
    );
  }

  logout() {
    // console.log('logout...');
    this.props.logout();
    window.location.reload();
  }
  handleOverlayClose() {
    //console.log("ùùùwswew");
  }

  render() {
    const cookies = new Cookies();
    return (
      <Router>
        <div className="App">
          <LoadingOverlay
            active={this.props.auth.overlayLoading}
            spinner={<Spinner />}
            spinnerSize="large"
            message="Loading..."
            onClick={this.handleOverlayClose}
          >
            <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
              <ToastContainer />
              <Header />

              <div>
                <Routes>{routes}</Routes>
              </div>
            </div>
            <Footer/>
          </LoadingOverlay>
          <GoTop scrollStepInPx="50" delayInMs="16.66" />
        </div>
      </Router>
    );
  }
  componentWillUnmount() {
    //TODO:: verificare SE viene chiamata
    //this.props.logoutUser(); //è necessario?
    this.clearTimeout();
    this.events.forEach((event) =>
      window.removeEventListener(event, this.resetTimeout)
    );
  }
}
const MyComponent = withTranslation()(LegacyComponentClass);

function App(props) {
  return (
    <Suspense
      fallback={<div className={"suspend-loading"}>Loading&#8230;</div>}
    >
      <MyComponent {...props} />
    </Suspense>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  refreshToken: () => dispatch(refreshToken()),
  logout: () => dispatch(logout()),
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
