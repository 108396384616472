import React from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const VerifyPhone = ({ user, isLoggedIn }) => {
  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }
  return (
    <div>
      <div className="content">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <div className="text-center mt-4">
                  <h1 className="h2">Verify Your Phone Number</h1>
                  <p className="lead">A message ith a code will be sent to the following number: <strong>{user?.mobilePhone}</strong></p>
				  <a className="btn btn-sm btn-primary m-2" ><FontAwesomeIcon icon={faRefresh}/> Send</a>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="m-sm-4">
                      <form>
                        <div className="form-group">
                          <label> Enter the Code</label>
                          <input
                            className="form-control form-control-lg"
                            type="number"
                            name="code"
                            placeholder=" Enter the 6-digit security code"
                          />
                        </div>
                        <div className="text-center mt-3">
							<Button type="submit" className="btn btn-lg btn-primary">
                            Verify
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  user: state.user.show.retrieved, // Adjust the state mapping based on your actual state structure
  isLoggedIn: state.auth.isLoggedIn,
});

export default connect(mapStateToProps)(VerifyPhone);
