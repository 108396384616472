import React, { useState, useEffect, useRef } from "react";
import Nav from "react-bootstrap/Nav";
import { Navbar, NavDropdown, Image  } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGears, faUser, faCogs, faDashboard } from "@fortawesome/free-solid-svg-icons";
import logo from '../../assets/img/logo.png';
const Header = () => {
  const [expanded, setExpanded] = useState();
  const isAuthenticated = useSelector((state) => state.auth.isLoggedIn);
  const navbarRef = useRef(null);

  useEffect(() => {

    // Remove the event listener when the component unmounts
    return () => {

    };
  }, []);

  const handleNavSelect = () => {
    // Close the navbar overlay
    //  setExpanded(!expanded);
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="light"
      variant="light"
      className=" ml-3 "
    >
      <Navbar.Brand as={Link} to="/">
      <Image src={logo} alt="Logo" width="30" height="30" />
        Creveton
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto"></Nav>
          {!isAuthenticated ? (
          <Nav className="">  
              <Nav.Link
                as={Link}
                to="/login"
                onClick={handleNavSelect}
                eventKey="login-link"
              >
                Login
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/register"
                onClick={handleNavSelect}
                eventKey="register-link"
              >
                Register
              </Nav.Link>
              </Nav>
          ) : (
            <Nav className="mr-5">
              <NavDropdown
                title={<FontAwesomeIcon icon={faCogs} />}
                id="collasible-nav-dropdown"
                className="mr-5"
              >
                <NavDropdown.Item >
                  <Nav.Link as={Link} to="/dashboard">
                    <FontAwesomeIcon icon={faDashboard} /> Dashboard
                  </Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item>
                  <Nav.Link as={Link} to="/logout">
                    Logout
                  </Nav.Link>
                </NavDropdown.Item>
              </NavDropdown>
           </Nav>
          )}
      </Navbar.Collapse>
    </Navbar>

    /*     <>
      <Navbar
        expanded={expanded}
        expand="sm"
        className="bg-body-tertiary mb-3"
        ref={navbarRef}
      >
        <Container fluid>
          <Navbar.Brand as={Link} to="/">
            Creveton
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls={`offcanvasNavbar-expand-sm`}
            onClick={handleNavClick}
          />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-sm`}
            aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
            placement="end"
            onClick={handleNavClick}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title
                id={`offcanvasNavbarLabel-expand-sm`}
                as={Link}
                to="/"
              >
                Creveton
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                {!isAuthenticated ? (
                  <>
                    <Nav.Link
                      as={Link}
                      to="/login"
                      onClick={handleNavSelect}
                      eventKey="login-link"
                    >
                      Login
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to="/register"
                      onClick={handleNavSelect}
                      eventKey="register-link"
                    >
                      Register
                    </Nav.Link>
                  </>
                ) : (
                  <>
                    <Nav.Link
                      as={Link}
                      to="/profile"
                      onClick={handleNavSelect}
                      eventKey="logout-link"
                    >
                      <FontAwesomeIcon icon={faUser} />
                    </Nav.Link>
                  </>
                )}
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </> */
  );
};

export default Header;
