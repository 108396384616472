import { combineReducers } from 'redux';

export function error(state = null, action) {
    switch (action.type) {
        case 'TRANSACTION_SHOW_ERROR':
            return action.error;

        case 'TRANSACTION_SHOW_RESET':
            return null;

        default:
            return state;
    }
}

export function retrieved(state = null, action) {
    switch (action.type) {
        case 'TRANSACTION_SHOW_SUCCESS':
            return action.retrieved;

        case 'TRANSACTION_SHOW_RESET':
            return null;

        default:
            return state;
    }
}

export default combineReducers({ error, retrieved});
