import { fetch } from "../../utils/dataAccess";
import { dispatchToast } from "./toastAction";
import { overlayLoading } from './overlayLoadingAction';


  export const resetPassword = (email) => {
  return (dispatch) => {
    return fetch("/api/reset-password", {
      method: "POST",
      body: JSON.stringify({
        email:email
      }),
    })
      .then((response) => response.json().then((retrieved) => ({ retrieved })))
      .then(({ retrieved }) => {
        dispatch(overlayLoading(false));
        dispatch({
          type: "EMAIL_CONFIRM",
          payload: true,
        });
      })
      .catch((e) => {
        dispatch(overlayLoading(false));
        dispatch(dispatchToast(["error", e.message, {}]));
      });
  };
};