import { fetch } from '../../utils/dataAccess';
import { logout } from './auth';
import jwtDecode from 'jwt-decode';
import Cookie from 'universal-cookie';
import { retrieve as retrieveUser } from '../actions/user/show';
import store from '../store';
import { overlayLoading } from './overlayLoadingAction';
import { dispatchToast } from "./toastAction";

const cookie = new Cookie();

export const refreshToken = () => {
    const user = store.getState().user.retrieved;
    return dispatch => {
        dispatch(overlayLoading(true));
        dispatch(loadRefreshing());
        fetch('/api/auth/refresh', {
            method: 'POST',
            headers: new Headers({
                Accept: 'application/json',
            }),
            body: JSON.stringify({
                refresh_token: cookie.get('refresh_token'),
            }),
        }).then(response =>
            response.json().then(retrieved => ({ retrieved })),
        ).then(({ retrieved }) => {
            dispatch(overlayLoading(false));
            const newToken = retrieved.token;
            const tokenPayload = jwtDecode(newToken);
            dispatch(refreshedToken(tokenPayload, newToken));

            if (!user || user.id !== tokenPayload.id)
                dispatch(retrieveUser());

        }).catch(e => {
            dispatch(overlayLoading(false));
            // console.log('refreshToken - e', e);
            dispatch(dispatchToast(['error', e.message, {}]));
            dispatch(logout());
        });
    };
};

export const refreshedToken = (tokenPayload, newToken) => ({
    type: 'REFRESHING_TOKEN',
    payload: tokenPayload,
    accessToken: newToken,
});


export const loadRefreshing = () => ({
    type: 'LOAD_REFRESH',
});


export const saveCurrentPath = path => ({
    type: 'SAVE_CURRENT_PATH',
    payload: path,
});

