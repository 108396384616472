import { combineReducers } from "redux";
import auth from "./auth";
import user from './user/';
import transaction from './transaction/';
import game from './game/';
/*import image from './image/';
import order from './order/';

import catalog from './catalog';
import review from './review/';
import criteria from './criteria/';
import iubenda from './iubenda/'; */

export default combineReducers({
  auth,
  user,
  transaction,
  game,
  // item, address, image, order,  catalog, review, criteria, iubenda
});
