import jwtDecode from "jwt-decode";
import { fetch } from "../../utils/dataAccess";
import Cookies from "universal-cookie";
import { dispatchToast } from "./toastAction";
import Constants from "../../constants/Constants";
import { retrieve as retrieveUser } from "../actions/user/show";
import { overlayLoading } from './overlayLoadingAction';


const cookies = new Cookies();

  export const login = (username, password, rememberMe) => {
  return (dispatch) => {
    dispatch(overlayLoading(true));
    return fetch("/api/auth", {
      method: "POST",
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    })
      .then((response) => response.json().then((retrieved) => ({ retrieved })))
      .then(({ retrieved }) => {
        dispatch(overlayLoading(false));
        const d = new Date();

        if (rememberMe) {
          d.setTime(
            d.getTime() + Constants.NUMBER_REMEMBERED_DAYS * 24 * 60 * 60 * 1000
          );
          cookies.set("refresh_token", retrieved.refresh_token, {
            path: "/",
            expires: d,
          });
          cookies.set("rememberMe", 1, { path: "/", expires: d });
        } else {
          cookies.set("refresh_token", retrieved.refresh_token, { path: "/" });
        }

        const accessToken = retrieved.token;
        const tokenPayload = jwtDecode(accessToken);
        dispatch({
          type: "LOGIN_SUCCESS",
          payload: { accessToken, tokenPayload },
        });

        dispatch(retrieveUser());
      })
      .catch((e) => {
        dispatch(overlayLoading(false));
        dispatch(dispatchToast(["error", e.message, {}]));
      });
  };
};
/*
export const register = (user) => {
  return (dispatch) => {
    dispatch(overlayLoading(true));
    return fetch("/api/register", {
      method: "POST",
      body: JSON.stringify({user}),
    })
      .then((response) => response.json().then((retrieved) => ({ retrieved })))
      .then(({ retrieved }) => {
        dispatch(overlayLoading(false));
        const d = new Date();
        dispatch({
          type: "REGISTER_SUCCESS",
          payload: true,
        });
        console.log("###retrieved", retrieved);
        /*
        if (rememberMe) {
          d.setTime(
            d.getTime() + Constants.NUMBER_REMEMBERED_DAYS * 24 * 60 * 60 * 1000
          );
          cookies.set("refresh_token", retrieved.refresh_token, {
            path: "/",
            expires: d,
          });
          cookies.set("rememberMe", 1, { path: "/", expires: d });
        } else {
          cookies.set("refresh_token", retrieved.refresh_token, { path: "/" });
        }

        const accessToken = retrieved.token;
        const tokenPayload = jwtDecode(accessToken);
        
        dispatch({
          type: "LOGIN_SUCCESS",
          payload: { accessToken, tokenPayload },
        });
       
      })
      .catch((e) => {
        dispatch(overlayLoading(false));
        dispatch(dispatchToast(["error", e.message, {}]));
      });
  };
};
*/
export const logout = () => {
  return (dispatch) => {
    cookies.remove("refresh_token", { path: "/" });
    cookies.remove("rememberMe", { path: "/" });
    dispatch({ type: "LOGOUT" });
  };
};

/*
export const loading = (value) => ({
  type: "LOGIN_LOADING",
  payload: value,
});
*/
