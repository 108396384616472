import React, { Component, lazy } from 'react';
import { Link } from "react-router-dom";
import ModalVideo from 'react-modal-video';
import '../../../../node_modules/react-modal-video/css/modal-video.min.css';
import '../../../../node_modules/react-modal-video/scss/modal-video.scss';
//import dynamic from 'next/dynamic';
import VisibilitySensor from "react-visibility-sensor";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import shape2 from "../../assets/img/shape/shape2.svg";
import shape3 from "../../assets/img/shape/shape3.svg";
import shape4 from "../../assets/img/shape/shape4.png";
import shape5 from "../../assets/img/shape/shape5.png";
import shape6 from "../../assets/img/shape/shape6.png";
import shape7 from "../../assets/img/shape/shape7.png";
import shape8 from "../../assets/img/shape/shape8.png";
import shape9 from "../../assets/img/shape/shape9.png";
import shape10 from "../../assets/img/shape/shape10.png";
import { Image  } from "react-bootstrap";
import i18n from "../../../i18n";

const options = {
    items: 1,
    loop: true,
    nav: true,
    dots: false,
    margin: 0,
    autoplayHoverPause: true,
    autoplay: true,
    mouseDrag: false,
    touchDrag: false,
    navText: [
        "<i class='flaticon-left-chevron'></i>",
        "<i class='flaticon-right-chevron'></i>"
    ],
}

class BannerSlider extends Component {

    _isMounted = false;
    state = {
        display:true
    }

    componentDidMount(){ 
        this._isMounted = true;
        this.setState({ display: true }) 
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    // Open Popup Modal
    state = {
        isOpen: false
    };

    openModal = () => {
        this.setState({isOpen: true})
    };

    render() {

        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true, // Enable autoplay
            autoplaySpeed: 3000, // Set the autoplay speed in milliseconds (1 second in this example)
          
          };

        return (
            <React.Fragment>
                {/* If you want change the video need to update below videoID */}
                <ModalVideo 
                    channel='youtube' 
                    isOpen={this.state.isOpen} 
                    videoId='mR3SdRxRRl4' 
                    onClose={() => this.setState({isOpen: false})} 
                    
                />
                {this.state.display  ? <Slider 
                    className="machine-learning-slider owl-carousel owl-theme"
                    {...settings}
                >
                    <div className="machine-learning-banner ml-bg1 jarallax">
                        <div className="d-table">
                            <div className="d-table-cell">
                                <div className="container mt-80">
                                    <div className="row align-items-center">
                                        <div className="col-lg-7">
                                            <VisibilitySensor>
                                                {({ isVisible }) => (
                                                    <div className="banner-content">
                                                        <h1
                                                        className={
                                                            isVisible ? "animated fadeInUp opacityOne" : 'opacityZero'
                                                            }
                                                        >{i18n.t('title-home-slider-1')}
                                                        </h1>

                                                        <p
                                                            className={
                                                                isVisible ? "animated fadeInUp opacityOne" : 'opacityZero'
                                                            }
                                                        >{i18n.t('description-home-slider-1')}</p>
                                                        
                                                        <div 
                                                            className={
                                                                `banner-btn ${isVisible ? "animated fadeInUp opacityOne" : 'opacityZero'}`
                                                            }
                                                        >
                                                            <Link to="/quiz">
                                                                <a className="default-btn mr-4">
                                                                    Quiz <span></span>
                                                                </a>
                                                            </Link>

                                                            <Link to="/register">
                                                                <a className="default-btn-two">
                                                                    Join Us <span></span>
                                                                </a>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                )}
                                            </VisibilitySensor>
                                        </div>

                                        <div className="col-lg-5">
                                            <div className="ml-video">
                                                <div className="solution-video">
                                                    <Link to="#play-video">
                                                        <a
                                                            onClick={e => {e.preventDefault(); this.openModal()}}
                                                            className="video-btn popup-youtube"
                                                        > 
                                                            <i className="flaticon-play-button"></i>
                                                        </a>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {/* Shape Images */}
                        <div className="shape-img2">
                            <Image src={shape2} alt="image" />
                        </div>
                        <div className="shape-img3">
                            <Image src={shape3} alt="image" />
                        </div>
                        <div className="shape-img4">
                            <Image src={shape4} alt="image" />
                        </div>
                        <div className="shape-img5">
                            <Image src={shape5} alt="image" />
                        </div>
                        <div className="shape-img6">
                            <Image src={shape6} alt="image" />
                        </div>
                        <div className="shape-img7">
                            <Image src={shape7} alt="image" />
                        </div>
                        <div className="shape-img8">
                            <Image src={shape8} alt="image" />
                        </div>
                        <div className="shape-img9">
                            <Image src={shape9} alt="image" />
                        </div>
                        <div className="shape-img10">
                            <Image src={shape10} alt="image" />
                        </div>
                    </div>

                    <div className="machine-learning-banner ml-bg2 jarallax">
                        <div className="d-table">
                            <div className="d-table-cell">
                                <div className="container mt-80">
                                    <div className="row align-items-center">
                                        <div className="col-lg-7">
                                            <VisibilitySensor>
                                                {({ isVisible }) => (
                                                    <div className="banner-content">
                                                        <h1
                                                        className={
                                                            isVisible ? "animated fadeInUp opacityOne" : 'opacityZero'
                                                            }
                                                        >{i18n.t('title-home-slider-2')}
                                                            
                                                        </h1>
                                                        <p
                                                            className={
                                                                isVisible ? "animated fadeInUp opacityOne" : 'opacityZero'
                                                            }
                                                        >{i18n.t('description-home-slider-2')}
                                                       </p>
                                                        
                                                        <div 
                                                            className={
                                                                `banner-btn ${isVisible ? "animated fadeInUp opacityOne" : 'opacityZero'}`
                                                            }
                                                        >
                                                            <Link to="/quiz">
                                                                <a className="default-btn mr-4">
                                                                    Quiz <span></span>
                                                                </a>
                                                            </Link>

                                                            <Link to="/register">
                                                                <a className="default-btn-two">
                                                                    Join Us <span></span>
                                                                </a>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                )}
                                            </VisibilitySensor>
                                        </div>

                                        <div className="col-lg-5">
                                            <div className="ml-video">
                                                <div className="solution-video">
                                                    <Link href="#play-video">
                                                        <a
                                                            onClick={e => {e.preventDefault(); this.openModal()}}
                                                            className="video-btn popup-youtube"
                                                        > 
                                                            <i className="flaticon-play-button"></i>
                                                        </a>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {/* Shape Images */}
                        <div className="shape-img2">
                            <Image src={shape2} alt="image" />
                        </div>
                        <div className="shape-img3">
                            <Image src={shape3} alt="image" />
                        </div>
                        <div className="shape-img4">
                            <Image src={shape4} alt="image" />
                        </div>
                        <div className="shape-img5">
                            <Image src={shape5} alt="image" />
                        </div>
                        <div className="shape-img6">
                            <Image src={shape6} alt="image" />
                        </div>
                        <div className="shape-img7">
                            <Image src={shape7} alt="image" />
                        </div>
                        <div className="shape-img8">
                            <Image src={shape8} alt="image" />
                        </div>
                        <div className="shape-img9">
                            <Image src={shape9} alt="image" />
                        </div>
                        <div className="shape-img10">
                            <Image src={shape10} alt="image" />
                        </div>
                    </div>

                    <div className="machine-learning-banner ml-bg3 jarallax">
                        <div className="d-table">
                            <div className="d-table-cell">
                                <div className="container mt-80">
                                    <div className="row align-items-center">
                                        <div className="col-lg-7">
                                            <VisibilitySensor>
                                                {({ isVisible }) => (
                                                    <div className="banner-content">
                                                        <h1
                                                        className={
                                                            isVisible ? "animated fadeInUp opacityOne" : 'opacityZero'
                                                            }
                                                        >{i18n.t('title-home-slider-3')}                                                           
                                                        </h1>
                                                        <p
                                                            className={
                                                                isVisible ? "animated fadeInUp opacityOne" : 'opacityZero'
                                                            }
                                                        >{i18n.t('description-home-slider-3')}
                                                       </p>
                                                        
                                                        <div 
                                                            className={
                                                                `banner-btn ${isVisible ? "animated fadeInUp opacityOne" : 'opacityZero'}`
                                                            }
                                                        >
                                                            <Link to="/quiz">
                                                                <a className="default-btn mr-4">
                                                                    Quiz <span></span>
                                                                </a>
                                                            </Link>

                                                            <Link href="register">
                                                                <a className="default-btn-two">
                                                                    Join Us <span></span>
                                                                </a>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                )}
                                            </VisibilitySensor>
                                        </div>

                                        <div className="col-lg-5">
                                            <div className="ml-video">
                                                <div className="solution-video">
                                                    <Link href="#play-video">
                                                        <a
                                                            onClick={e => {e.preventDefault(); this.openModal()}}
                                                            className="video-btn popup-youtube"
                                                        > 
                                                            <i className="flaticon-play-button"></i>
                                                        </a>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {/* Shape Images */}
                        <div className="shape-img2">
                            <Image src={shape2} alt="image" />
                        </div>
                        <div className="shape-img3">
                            <Image src={shape3} alt="image" />
                        </div>
                        <div className="shape-img4">
                            <Image src={shape4} alt="image" />
                        </div>
                        <div className="shape-img5">
                            <Image src={shape5} alt="image" />
                        </div>
                        <div className="shape-img6">
                            <Image src={shape6} alt="image" />
                        </div>
                        <div className="shape-img7">
                            <Image src={shape7} alt="image" />
                        </div>
                        <div className="shape-img8">
                            <Image src={shape8} alt="image" />
                        </div>
                        <div className="shape-img9">
                            <Image src={shape9} alt="image" />
                        </div>
                        <div className="shape-img10">
                            <Image src={shape10} alt="image" />
                        </div>
                    </div>
                </Slider> : ''}
            </React.Fragment>
        );
    }
}

export default BannerSlider;