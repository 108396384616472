

export function loadQuestionnaires  (questionnaires) { 
    return { type: 'LOAD_QUESTIONNAIRES', payload: questionnaires }
};
export function answerQuestion  (answer) {
    return { type: 'ANSWER_QUESTION', payload: answer }
};

export function nextQuestion() {
    return {
    type: 'NEXT_QUESTION',
  }
};