import React from 'react';
import { connect } from "react-redux";
import BannerSlider from "../components/layout/BannerSlider";
import RecentStory from "../components/layout/RecentStory";
import SubscribeBoxedTwo from "../components/layout/SubscribeBoxedTwo";
import FunFacts from "../components/layout/FunFacts";
import FeaturedServices from '../components/layout/FeaturedServices';
import Meta from '../components/layout/Meta';
import i18n from '../../i18n';

function LandingPage() {
  return (
<React.Fragment>
<Meta
        title={i18n.t("home-title")}
        description={i18n.t("home-page-title")}
      />
<BannerSlider />
<FeaturedServices />
<FunFacts />
{/*<RecentStory />*/}
<SubscribeBoxedTwo/>
</React.Fragment>
  );
}

const mapStateToProps = (state) => ({
    user: state.user.show.retrieved,
    balance: state.user.show.retrieved?.accounts,
    isLoggedIn: state.auth.isLoggedIn,
  });
  
  export default connect(mapStateToProps)(LandingPage);