import React from "react";
import { Route } from "react-router-dom";
import Home from "../pages/Home";
/* import Confirmed from '../pages/Login/Confirmed';
import Dashboard from '../pages/Admin/Dashboard';
import RegisterAffiliate from '../pages/Login/RegisterAffiliate';
import Affiliate from '../pages/Affiliate/Affiliate';
import Affiliates from '../pages/Affiliate/Affiliates';
import Item from '../pages/Item/Item';

import Error404 from '../pages/Error/Error404';*/
import Operations from '../pages/Operations';
import ConfirmEmail from '../pages/ConfirmEmail';
import EditProfile from '../pages/EditProfile';
import VerifyPhone from '../pages/VerifyPhone'; 
import Logout from "../components/login/Logout";
import Register from "../components/login/Register";
import Login from "../components/login/Login";
import QRCodeScan from "../pages/QRCodeScan";
import Profile from "../pages/Profile";
import QRCodeGenerator from "../pages/QRCodeGenerator";
import Error404 from "../pages/Error404";
import LandingPage from "../pages/LandingPage";
import Questionnaire from "../pages/Questionnaire";
import QuestionnaireResult from "../pages/QuestionnaireResult";
import ResetPassword from '../pages/ResetPassword';
import Contact from "../pages/Contact";
import PrivacyPolicy from "../pages/PrivacyPolicy";

const routes = [

  <Route exact path="/login" element={<Login />} key="login" />,
  <Route path="/logout" element={<Logout />} exact key="logout" />,
  <Route path="/register" element={<Register />} exact key="register" />,
  <Route path="/dashboard" element={<Home />} exact key="dashboard" />,
  <Route path="/" element={<LandingPage />} exact key="home" />,
  <Route path="/scan-qr" element={<QRCodeScan />} exact key="scan-qr" />,
  <Route path="/operations" element={<Operations />} exact key="operations" />,
  <Route path="/generate-qr" element={<QRCodeGenerator />} exact key="generate-qr" />,
  <Route path="/profile" element={<Profile />} exact key="profile" />,
  <Route path="/verify-phone" element={<VerifyPhone/>} exact key="verify-phone"/>,
  <Route path="/edit-profile" element={<EditProfile/>} exact key="edit-profile"/>,
  <Route path="/quiz" element={<Questionnaire/>} exact key="quiz"/>,
  <Route path="/quiz-results" element={<QuestionnaireResult/>} exact key="quiz-result"/>,
  <Route path="/confirm-email/:expires/:token" element={<ConfirmEmail/>} exact key="confirm-email"/>,
  <Route path="/reset-password/:expires?/:token?" element={<ResetPassword/>} />,
  <Route path="/contact" element={<Contact />} exact key="contact" />,  
  <Route path="/privacy-policy" element={<PrivacyPolicy/>} key="privacy policy"/>,
/*
    <Route path="/order" component={Order} exact key="order"/>,
    <Route path="/order/show/:id" component={Show} exact key="show-order"/>,
    <Route path="/dashboard" exact component={Dashboard} key="dashboard"/>,
    <Route path="/dashboard/:section/:id?" component={Dashboard} key="dashboards"/>,

    <Route path="/search" component={Items} key="search"/>,
    <Route path="/item/show/:id" component={Item} exact key="show"/>,

    <Route path="/affiliates" component={Affiliates} key="affiliates"/>,
    <Route path="/affiliate/show/:id/:section/:sid/:sname?" component={Affiliate} key="affiliate"/>,
    <Route path="/affiliate/show/:id/:section" component={Affiliate} key="affiliate"/>,
    <Route path="/affiliate/show/:id" component={Affiliate} key="affiliate"/>,

    <Route path="/conditions" render={props => <Static {...props} pageTitle={i18n.t('terms-and-conditions')}/>}
           key="condition"/>,
    <Route path="/contact" render={props => <Contact {...props} pageTitle={i18n.t('contact')}/>} key="contact"/>,
    <Route path="/faq" render={props => <Static {...props} pageTitle="FAQ"/>} key="faq"/>,
    <Route path="/about" render={props => <Static {...props} pageTitle={i18n.t('about-us')}/>} key="about"/>,

    <Route path="/cookie-policy" render={props => <Static {...props} pageTitle={i18n.t('cookie-policy')}/>}
           key="cookie policy"/>,
    <Route path="/plus" render={props => <Static {...props} pageTitle={i18n.t('plus-subscription')} />}
           key="plus"/>,*/
    <Route path="*" element={<Error404/>} key="error404"/>, 
];

export default routes;
