import React, {useState, useEffect} from 'react';
import { connect } from "react-redux";
import {
	Form,
	Button,
	InputGroup
  } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUnlockAlt} from "@fortawesome/free-solid-svg-icons";
import { resetPassword } from "../redux/actions/resetPassword";
import { useParams } from 'react-router-dom';


const ResetPassword = (props) => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [passwordMatch, setPasswordMatch] = useState(true);
	const { expires, token } = useParams();

	const handleInputChange = (e) => {
		const { name, value, type, checked } = e.target;

		if(name === "password"){
			setPassword(value);
		} else if(name === "confirmPassword") {
			setConfirmPassword(value);
		} else if(name === "email"){
			setEmail(value)
		}
	  };
	

	  useEffect(() => {

		if (confirmPassword != "" & (password !== confirmPassword)) {
			setPasswordMatch(false);
		  } else {
			setPasswordMatch(true);

		  }

		return () => {
		 // props.registerComplete();
		};
	  }, [password, confirmPassword ]);

	  const handleSubmit = (e) => {
		e.preventDefault();
		if(expires){

		props.launchOverlay(true);

		}else{
		props.resetPassword(email).then((result) => {

			// Handle successful login
			console.log("Reset successful:", result);
			/*if(props.redirectPath != null){
				navigate(props.redirectPath);
			} else{
			  navigate('/dashboard');
			}
			*/
		  })
		  .catch((error) => {
			  // Handle login error
			  console.error("Reset failed:", error);
			  // You can display an error message or handle the error in some other way
		  })
		  .finally(() => {
			  // This block will be executed regardless of success or failure
			  props.launchOverlay(false); // Assuming this stops the overlay
		  });
		}
	  }

	return (
		<div>
			<div className="container h-100">
    		<div className="row h-100">
				<div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
					<div className="d-table-cell align-middle">
						<div className="text-center mt-4">
							<h1 className="h2">Reset password</h1>
							<p className="lead">
								Enter your email to reset your password.
							</p>
						</div>
						<div className="">
							<div className="">
								<div className="m-sm-4">
								<Form className="mt-4" onSubmit={handleSubmit}>
								{expires ? <>
								<Form.Group id="password" className="mb-4">
								<Form.Label>New Password</Form.Label>
								<InputGroup>
								<InputGroup.Text>
									<FontAwesomeIcon icon={faUnlockAlt} />
								</InputGroup.Text>
								<Form.Control
									autoFocus
									required
									type="password"
									name="password"
									placeholder="New Password"
									value={password}
									onChange={handleInputChange}
								/>
								</InputGroup>
							</Form.Group>
							{!passwordMatch && (
                      <p className="text-danger">Passwords do not match.</p>
                    )}
							<Form.Group id="confirmPassword" className="mb-4">
								<Form.Label>Confirm Password</Form.Label>
								<InputGroup>
								<InputGroup.Text>
									<FontAwesomeIcon icon={faUnlockAlt} />
								</InputGroup.Text>
								<Form.Control
									autoFocus
									required
									type="password"
									name="confirmPassword"
									placeholder="Confirm Password"
									value={confirmPassword}
									onChange={handleInputChange}
								/>
								</InputGroup>
							</Form.Group>
								</>
:
								<Form.Group id="email" className="mb-4">
								<Form.Label>Your Email</Form.Label>
								<InputGroup>
								<InputGroup.Text>
									<FontAwesomeIcon icon={faEnvelope} />
								</InputGroup.Text>
								<Form.Control
									autoFocus
									required
									type="email"
									name="email"
									placeholder="Enter your email"
									value={email}
									onChange={handleInputChange}
								/>
								</InputGroup>
							</Form.Group>
							}
							<Button variant="primary" type="submit" className="w-100">
							Reset password
							</Button>
									</Form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	user: state.user.show.retrieved, // Adjust the state mapping based on your actual state structure
	isLoggedIn: state.auth.isLoggedIn,
  });

  const mapDispatchToProps = dispatch => ({
	resetPassword: (value) => dispatch(resetPassword(value)),
	launchOverlay: (value) =>dispatch({
	  type: "OVERLAY_LOADING",
	  payload: value,
	}),
  });

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);