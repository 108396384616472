import React from "react";
import { Navigate, Link } from "react-router-dom";
import { connect } from "react-redux";

function EditProfile({ user, isLoggedIn }) {

    if (!isLoggedIn) {
        return <Navigate to="/login" />;
      }

  return (
    <div>
    <div className="content">
      <div className="container mt-5">
        <div className="row">
          <div className="col-lg-4 pb-5">
            <div className="author-card pb-3">
              <div
                className="author-card-cover"
                style={
                  {
                    /*background-image: url(https://bootdey.com/img/Content/flores-amarillas-wallpaper.jpeg);*/
                  }
                }
              >
                <a
                  className="btn btn-style-1 btn-white btn-sm"
                  href="#/"
                  data-toggle="tooltip"
                  title=""
                  data-original-title="You currently have 290 Reward points to spend"
                >
                  <i className="fa fa-award text-md"></i>&nbsp;290 points
                </a>
              </div>
              <div className="author-card-profile">
                <div className="author-card-avatar">
                  <img
                    src="https://bootdey.com/img/Content/avatar/avatar1.png"
                    alt="Daniel Adams"
                  />
                </div>
                <div className="author-card-details">
                  <h5 className="author-card-name text-lg">{user.firstName} {user.lastName}</h5>
                  <span className="author-card-position">
                    Joined February 06, 2017
                  </span>
                </div>
              </div>
            </div>
            <div className="wizard">
              <nav className="list-group list-group-flush">
                <a className="list-group-item" href="#/">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <i className="fe-icon-shopping-bag mr-1 text-muted"></i>
                      <div className="d-inline-block font-weight-medium text-uppercase">
                        Orders List
                      </div>
                    </div>
                    <span className="badge badge-secondary">6</span>
                  </div>
                </a>
                <a className="list-group-item active" href="#/">
                  <i className="fe-icon-user text-muted"></i>Profile Settings
                </a>
                <a className="list-group-item" href="#/">
                  <i className="fe-icon-map-pin text-muted"></i>Addresses
                </a>
                <a
                  className="list-group-item"
                  href="#"
                  target="__blank"
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <i className="fe-icon-heart mr-1 text-muted"></i>
                      <div className="d-inline-block font-weight-medium text-uppercase">
                        My Wishlist
                      </div>
                    </div>
                    <span className="badge badge-secondary">3</span>
                  </div>
                </a>
                <a
                  className="list-group-item"
                  href="#"
                  target="__blank"
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <i className="fe-icon-tag mr-1 text-muted"></i>
                      <div className="d-inline-block font-weight-medium text-uppercase">
                        My Tickets
                      </div>
                    </div>
                    <span className="badge badge-secondary">4</span>
                  </div>
                </a>

                <a
                  className="list-group-item"
                  href="#"
                  target="__blank"
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <i className="fe-icon-tag mr-1 text-muted"></i>
                      <div className="d-inline-block font-weight-medium text-uppercase">
                        <Link to="/reset-password">Update Password
                        </Link>
                      </div>
                    </div>
                  </div>
                </a>
                
              </nav>
            </div>
          </div>

          <div className="col-lg-8 pb-5">
            <form className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="account-fn">First Name</label>
                  <input
                    className="form-control"
                    type="text"
                    id="account-fn"
                    value={user.firstName}
                    required=""
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="account-ln">Last Name</label>
                  <input
                    className="form-control"
                    type="text"
                    id="account-ln"
                    value={user.lastName}
                    required=""
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="account-email">E-mail Address</label>
                  <input
                    className="form-control"
                    type="email"
                    id="account-email"
                    value={user.email}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="account-phone">Phone Number</label>
                  <input
                    className="form-control"
                    type="text"
                    id="account-phone"
                    value={user.mobilePhone}
                    required=""
                  />
                </div>
              </div>

              <div className="col-12">
                <hr className="mt-2 mb-3" />
                <div className="d-flex flex-wrap justify-content-between align-items-center">
                  <div className="custom-control custom-checkbox d-block">
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      id="subscribe_me"
                      checked=""
                    />
                    <label className="custom-control-label" htmlFor="subscribe_me">
                      Subscribe me to Newsletter
                    </label>
                  </div>
                  <button
                    className="btn btn-style-1 btn-primary"
                    type="button"
                    data-toast=""
                    data-toast-position="topRight"
                    data-toast-type="success"
                    data-toast-icon="fe-icon-check-circle"
                    data-toast-title="Success!"
                    data-toast-message="Your profile updated successfuly."
                  >
                    Update Profile
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.show.retrieved, // Adjust the state mapping based on your actual state structure
  isLoggedIn: state.auth.isLoggedIn,
});

export default connect(mapStateToProps)(EditProfile);
