import { useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const AlertDismissible = ({ variant }) => {
  const [show, setShow] = useState(true);

  return (
    <>
      <Alert show={show} variant={variant}>
        <Alert.Heading>
          <FontAwesomeIcon icon={faWarning} /> Alert
        </Alert.Heading>
        <p>
          <strong>Important:</strong> Please verify your phone number to enhance
          your account security.
        </p>
        <hr />
        <div className="d-flex justify-content-end">
          <Link className="btn" to="/verify-phone">
            Verify Phone
          </Link>
        </div>
      </Alert>

      {!show && <Button onClick={() => setShow(true)}>Verify</Button>}
    </>
  );
};

export default AlertDismissible;
