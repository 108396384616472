import store from '../store';

export function makeCancelable(name) {
    return dispatch => {
        dispatch({ type: 'ABORT', name });

        const abortController = store.getState().auth.abortController[name];
        if (abortController instanceof AbortController)
            return abortController.signal;
        return null;
    };
}

export function abort(name) {
    const abortController = store.getState().storeState.root.abortController[name];
    if (abortController instanceof AbortController)
        abortController.abort();
}

export function abortAll() {
    for (const abortControllerName in store.getState().storeState.root.abortController) {
        if (store.getState().storeState.root.abortController.hasOwnProperty(abortControllerName)) {
            const abortController = store.getState().storeState.root.abortController[abortControllerName];
            if (abortController instanceof AbortController)
                abortController.abort();
        }
    }
}
