import { overlayLoading } from './overlayLoadingAction';
import { dispatchToast } from "./toastAction";
import { fetchAuth, UnauthorizedError } from "../../utils/dataAccess";
import { refreshToken } from './refreshingToken';

export const transactionSend = (id, amount, ) => {
    return (dispatch) => {
      dispatch(overlayLoading(true));
      return fetchAuth("api/transaction-send", {
        method: "POST",
        body: JSON.stringify({
            "uuid":id,
            "amount": amount,
        }),
      })
        .then((response) => response.json().then((retrieved) => ({ retrieved })))
        .then(({ retrieved }) => {
            dispatch(overlayLoading(false));
            dispatch(dispatchToast(["success", "Transaction Completed successfully.", {}]));


        }).catch(e => {
            dispatch(overlayLoading(false));
            if (e.code === 20) return; // abort occurred
            if (e instanceof UnauthorizedError){
                dispatch(overlayLoading(true));
                dispatch({
                    type: "TRANSACTION_UUID",
                    payload: "",
                  });
               return dispatch(refreshToken()); 
            } 
            dispatch(dispatchToast(["error", e.message, {}]));
        });
    }
}

export function error(error) {
    return { type: 'TRANSACTION_SHOW_ERROR', error };
}

export function transactionUuid(uuid){
    return {type: 'TRANSACTION_UUID', payload: uuid};
}