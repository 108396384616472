import React, { Component } from "react";
import emailjs from "emailjs-com";
import i18n from "../../../i18n";
import { toast } from "react-toastify";

class ContactForm extends Component {
  sendEmail(e) {
    e.preventDefault();
    const formElements = e.target.elements;

    if (!formElements.phone_work.value) {
      let templateParams = {
        reply_to: formElements.email.value,
        to_name: "Creveton Team", // or any default value
        from_name: formElements.name.value,
        subject: formElements.subject.value,
        message: formElements.message.value,
        phone_number: formElements.phone_number
          ? formElements.phone_number.value
          : "",
      };

      emailjs
        .send(
          "service_ho19uxr",
          "template_6xlckcj",
          templateParams,
          "NXW1rRzY_XxINreTP"
        )
        .then(
          (response) => {
            console.log("SUCCESS!", response.status, response.text);
            toast.success(response.text, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
          },
          (err) => {
            console.log("FAILED...", err);
          }
        );
    }
    e.target.reset();
  }

  render() {
    return (
      <section className="contact-area ptb-100">
        <div className="container">
          <div className="section-title">
            <h3>{i18n.t("contact-sub-title")}</h3>
            <p>{i18n.t("contact-description")}</p>
          </div>

          <div className="row align-items-center">
            <div className="col-lg-4 col-md-4">
              <div className="contact-image">
                <img
                  src={require("../../assets/img/contact.jpg")}
                  alt="Contact"
                  style={{ width: "400px", height: "400px" }}
                />
              </div>
            </div>

            <div className="col-lg-8 col-md-8">
              <div className="contact-form">
                <form id="contactForm" onSubmit={this.sendEmail}>
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="Name"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder="Email"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          name="phone_number"
                          className="form-control"
                          placeholder="Phone"
                        />
                      </div>
                    </div>
                    <input type="hidden" name="phone_work" value="" />

                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          name="subject"
                          className="form-control"
                          placeholder="Subject"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <textarea
                          name="message"
                          className="form-control"
                          rows="5"
                          placeholder="Your Message"
                          required
                        ></textarea>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12 text-center">
                      <button type="submit" className="default-btn">
                        Send Message <span></span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ContactForm;
