import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducers"; // Create your reducers
import thunk from "redux-thunk";
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from "redux-devtools-extension";
import { reducer as form } from 'redux-form';

const history = createBrowserHistory();

const enhancerList = [applyMiddleware(thunk)];

// Conditionally add Redux DevTools extension in development
/*if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
  if (typeof devToolsExtension === 'function') {
    enhancerList.push(devToolsExtension());
  }
}*/

const store = createStore(
  rootReducer,
  composeWithDevTools(...enhancerList)
);

export default store;
