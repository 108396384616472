import React, {useEffect, useState} from "react";
import { nextQuestion } from "../redux/actions/questionAction";
import 'survey-core/defaultV2.min.css';
import { Model, Serializer } from 'survey-core';
import { connect } from "react-redux";
import { Survey } from 'survey-react-ui';
import { getGame } from "../redux/actions/getGameAction"; 
import i18n from "../../i18n";
import Meta from "../components/layout/Meta";

Serializer.addProperty("question", {
    name: "score:number"
  });
function Questionnaire(props) {
    const [game, setGame] = useState();

    useEffect(() => {
        props.launchOverlay(true);

        props.getGame()
        .then((result) => {
            // Handle successful login
        })
        .catch((error) => {
            // Handle login error
            console.error("Login failed:", error);
            // You can display an error message or handle the error in some other way
        })
        .finally(() => {
            // This block will be executed regardless of success or failure
            props.launchOverlay(false); // Assuming this stops the overlay
        });
       // setGame(props.game);
        return () => {
           // props.confirmComplete();
          }; 

      }, []);

    const surveyJson = {
        title: "~",
        showProgressBar: "bottom",
        showTimerPanel: "top",
        maxTimeToFinishPage: 30,
        maxTimeToFinish: 300,
        firstPageIsStarted: true,
        startSurveyText: i18n.t('start-quiz'),
        //completedHtml: "<h4>You got <b>{correctAnswers}</b> out of <b>{questionCount}</b> correct answers.</h4>",

        completedHtmlOnCondition: [{
            "expression": "{totalScore} > 14",
            "html": i18n.t('question-result-1')
          }, {
            "expression": "{totalScore} > 7",
            "html": i18n.t('question-result-2')
          }, {
            "expression": "{totalScore} <= 7",
            "html": i18n.t('question-result-3')
          }],

        pages: [{
            elements: [{
                type: "html",
                html: i18n.t('quiz-welcome-message')
            },        
        ]
        }, 
    ]
    };    

    function getRandomElements(arr, num) {
      // Shuffle array using Fisher-Yates algorithm
      for (let i = arr.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [arr[i], arr[j]] = [arr[j], arr[i]]; // Swap elements
      }
      // Slice the first `num` elements after shuffle
      return arr.slice(0, num);
  }

    if(props.game){
    const quizType = props.game.title;
    surveyJson.pages[0].elements[0].html = i18n.t('quiz-welcome-message');

    surveyJson.title = props.game.title

    const randomElements = getRandomElements(props.game.questions, 10);
    randomElements.forEach((element) => {

        surveyJson.pages.push(
            {
                elements: [
                    {
                        type: element.question_type,
                        name: element.text,
                        title: element.text,
                        choices: Object.values(element.options),
                        correctAnswer: Object.values(element.correctAnswer)[0],
                        score: 1
                    }
                ]
            }
        );
    })
}

  const survey = new Model(surveyJson);

  survey.onComplete.add((sender) => {
    //const totalScore = calculateTotalScore(sender.data, survey);
    const maxScore = calculateMaxScore(survey.getAllQuestions());
    
    // Save the scores in survey results
    sender.setValue("maxScore", maxScore);
});

function calculateMaxScore(questions) {
    let maxScore = 0;
    questions.forEach((question) => {
        if (question.score) {
            maxScore += question.score;
        }
    });
    return maxScore;
}

function calculateTotalScore(data) {
    var totalScore = 0;


    console.log("###data", data);
    
    data.forEach((item) => {
      const question = survey.getQuestionByValueName(item.title);
      
        // Check if the answer is correct
        if (question.isAnswerCorrect()) {
            totalScore += question.score || 0; // Add the score if correct
        }

    });
    return totalScore;
  }

  survey.onCompleting.add((sender) => {
    const plainData = sender.getPlainData({
        // Include `score` values into the data array
        calculations: [{ propertyName: "score" }]
      });
      const totalScore = calculateTotalScore(plainData);

    sender.setValue("totalScore", totalScore);
  });

    survey.startTimer();
    survey.stopTimer();

  return (
    <div>
      <Meta title={i18n.t("quiz-title")}
        description={i18n.t("quiz-description")}
      />
      
        <Survey model={survey} />
    </div>
  );
}

const mapStateToProps = (state) => ({
    game : state.game.show.retrieved,
    });
  
    const mapDispatchToProps = dispatch => ({
      nextQuestion: () => dispatch(nextQuestion()),
      getGame: () => dispatch(getGame()),
      launchOverlay: (value) =>dispatch({
        type: "OVERLAY_LOADING",
        payload: value,
      }),
    });
    
    export default connect(mapStateToProps, mapDispatchToProps)(Questionnaire);