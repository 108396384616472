import { overlayLoading } from './overlayLoadingAction';
import { dispatchToast } from "./toastAction";
import { fetch, UnauthorizedError } from "../../utils/dataAccess";
import { refreshToken } from './refreshingToken';


export function getGame(uuid =Math.floor(Math.random() * (5 - 1 + 1)) + 1)  {
    return (dispatch) => {
      //dispatch(overlayLoading(true));
      return fetch(`api/games/${uuid}`)
        .then((response) => response.json().then((retrieved) => ({ retrieved })))
        .then(({ retrieved }) => {
            dispatch(overlayLoading(false));

            //console.log('####retrieved',retrieved);
           dispatch(successGame(retrieved));
           //dispatch(transactionUuid(retrieved.uuid))

        }).catch(e => {
            dispatch(overlayLoading(false));
            if (e.code === 20) return; // abort occurred
            if (e instanceof UnauthorizedError){
                dispatch(overlayLoading(true));

               return dispatch(refreshToken()); 
            } 
            dispatch(error(e.message));
        });
    }
}

export function transactionUuid(uuid){
    return {type: 'GAME_UUID', payload: uuid};
}

export function error(error) {
    return { type: 'GAME_SHOW_ERROR', error };
}

export function successGame(retrieved) {
    return { type: 'GAME_SHOW_SUCCESS', retrieved };
}
