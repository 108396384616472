import { combineReducers } from 'redux';

export function updated(state = null, action) {
    switch (action.type) {
        case 'USER_UPDATE_SUCCESS':
            return action.updated;

        case 'USER_SHOW_RESET':
            return null;

        default:
            return state;
    }
}

export default combineReducers({ updated });
