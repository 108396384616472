import React, { useState } from "react";
import { Link } from "react-router-dom";

import thumb1 from "../../assets/img/portfolio/thumbs/portfolio-1.jpg";
import thumb2 from "../../assets/img/portfolio/thumbs/portfolio-2.jpg";
import thumb3 from "../../assets/img/portfolio/thumbs/portfolio-3.jpg";

import thumb4 from "../../assets/img/portfolio/thumbs/portfolio-4.jpg";
import thumb5 from "../../assets/img/portfolio/thumbs/portfolio-5.jpg";
import thumb6 from "../../assets/img/portfolio/thumbs/portfolio-6.jpg";

import thumb7 from "../../assets/img/portfolio/thumbs/portfolio-7.jpg";
import thumb8 from "../../assets/img/portfolio/thumbs/portfolio-8.jpg";
import thumb9 from "../../assets/img/portfolio/thumbs/portfolio-9.jpg";

import fullImage1 from "../../assets/img/portfolio/portfolio-1.jpg"; // Full-size images
import fullImage2 from "../../assets/img/portfolio/portfolio-2.jpg";
import fullImage3 from "../../assets/img/portfolio/portfolio-3.jpg";
import fullImage4 from "../../assets/img/portfolio/portfolio-4.jpg";
import fullImage5 from "../../assets/img/portfolio/portfolio-5.jpg";
import fullImage6 from "../../assets/img/portfolio/portfolio-6.jpg";
import fullImage7 from "../../assets/img/portfolio/portfolio-7.jpg";
import fullImage8 from "../../assets/img/portfolio/portfolio-8.jpg";
import fullImage9 from "../../assets/img/portfolio/portfolio-9.jpg";

const Footer = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Thumbnails and corresponding full-size images
  const thumbnails = [
    thumb1,
    thumb2,
    thumb3,
    thumb4,
    thumb5,
    thumb6,
    thumb7,
    thumb8,
    thumb9,
  ];
  const fullImages = [
    fullImage1,
    fullImage2,
    fullImage3,
    fullImage4,
    fullImage5,
    fullImage6,
    fullImage7,
    fullImage8,
    fullImage9,
  ];

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const showNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % fullImages.length);
  };

  const showPrevImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + fullImages.length) % fullImages.length
    );
  };

  let currentYear = new Date().getFullYear();
  return (
    <React.Fragment>
      <section className="footer-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <h3>Contact Info</h3>

                <ul className="footer-contact-info">
                  <li>
                    <i className="flaticon-phone-call"></i>
                    <span>Mon to Fri : 10:00AM - 06:00PM</span>
                    <Link href="#">
                      <a>+237 695188319</a>
                    </Link>
                  </li>
                  <li>
                    <i className="flaticon-email"></i>
                    <span>Do You Have a Question?</span>
                    <Link href="#">
                      <a>support@creveton.online</a>
                    </Link>
                  </li>
                  <li>
                    <i className="flaticon-social-media"></i>
                    <span>Socials Network</span>

                    <ul className="social">
                      <li>
                        <Link href="#">
                          <a>
                            <i className="fab fa-twitter"></i>
                          </a>
                        </Link>
                      </li>
                      <li>
                        <Link href="#">
                          <a>
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </Link>
                      </li>
                      <li>
                        <Link href="#">
                          <a>
                            <i className="fab fa-instagram"></i>
                          </a>
                        </Link>
                      </li>
                      <li>
                        <Link href="#">
                          <a>
                            <i className="fab fa-linkedin"></i>
                          </a>
                        </Link>
                      </li>
                      <li>
                        <Link href="#">
                          <a>
                            <i className="fab fa-youtube"></i>
                          </a>
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-footer-widget pl-5">
                <h3>Quick Links</h3>

                <ul className="footer-quick-links">
                  <li>
                    <Link to="/">
                      <a>Home</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/about">
                      <a>About</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog">
                      <a>Blog</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact">
                      <a>Contact</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/suport">
                      <a>Support</a>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
              <div className="single-footer-widget pl-5">
                <h3>Portfolio</h3>
                <ul className="footer-instagram-post">
                  {thumbnails.map((thumbnail, index) => (
                    <li key={index}>
                      <a href="#" onClick={() => handleImageClick(index)}>
                        <img src={thumbnail} alt={`portfolio-${index}`} />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          {/* Modal for Image Preview */}
          {showModal && (
            <div
              className="modal show fade d-block"
              tabIndex="-1"
              onClick={closeModal}
              style={{ background: "rgba(0, 0, 0, 0.8)" }}
            >
              <div
                className="modal-dialog modal-lg"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="btn-close"
                      onClick={closeModal}
                    ></button>
                  </div>
                  <div className="modal-body text-center">
                    <img
                      src={fullImages[currentImageIndex]}
                      alt="modal preview"
                      className="img-fluid"
                    />
                    {/* Navigation Buttons */}
                    <button
                      className="btn btn-primary position-absolute top-50 start-0"
                      onClick={showPrevImage}
                    >
                      &#10094;
                    </button>
                    <button
                      className="btn btn-primary position-absolute top-50 end-0"
                      onClick={showNextImage}
                    >
                      &#10095;
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="copyright-area">
            <div className="row align-items-center">
              <div className="col-lg-6 col-sm-6 col-md-6">
                <p>
                  Copyright @{currentYear} Creveton. All rights reserved{" "}
                  <a href="#" target="_blank">
                    NG Group
                  </a>
                </p>
              </div>

              <div className="col-lg-6 col-sm-6 col-md-6">
                <ul>
                  <li>
                    <Link to="/terms-and-conditions">
                      <a>Terms & Conditions</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">
                      <a>Privacy Policy</a>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Footer;
