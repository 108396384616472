import { fetch } from "../../utils/dataAccess";
import { dispatchToast } from "./toastAction";
import { overlayLoading } from './overlayLoadingAction';


  export const confirmEmail = (expires, token) => {
  return (dispatch) => {
    return fetch("/api/confirm-email", {
      method: "POST",
      body: JSON.stringify({
        expires:expires,  
        token: token
      }),
    })
      .then((response) => response.json().then((retrieved) => ({ retrieved })))
      .then(({ retrieved }) => {
        dispatch(overlayLoading(false));
        dispatch({
          type: "EMAIL_CONFIRM",
          payload: true,
        });
      })
      .catch((e) => {
        dispatch(overlayLoading(false));
        dispatch(dispatchToast(["error", e.message, {}]));
      });
  };
};