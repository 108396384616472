import React, {useEffect} from "react";
import { Navigate, Link } from "react-router-dom";
import { connect } from "react-redux";
import Meta from "../components/layout/Meta";
import  i18n  from "../../i18n";
import { useParams } from 'react-router-dom';
import { confirmEmail } from "../redux/actions/confirmEmail"

function ConfirmEmail(props) {
    const { expires, token } = useParams();

    useEffect(() => {
        props.launchOverlay(true);
        if(token)
        props.confirmEmail(expires, token);
         return () => {
          props.confirmComplete();
        }; 
      }, [token]);

    return(
        <div >
            <div className ="content">
                <div className="containt">
                {!props.isConfirm ? (
                    <>
                        <Meta title={i18n.t('user-not-found')}/>
                        <section className="text-center pl_3 pr_3">
                            <h2>{i18n.t('user-not-found')}</h2>
                            <p>
                                {i18n.t('error-bad-request')}
                            </p>
                            <Link to="/" className={'btn btn-primary'}>
                                {i18n.t('back-to-home')}
                            </Link>
                        </section>
                    </>
                ) : (
                    <>
                        <Meta title={i18n.t('email-confirmed')}/>
                        <section className="text-center pl_3 pr_3">
                            <h2>{i18n.t('email-confirmed')}</h2>
                            <p>
                                {i18n.t('confirm-text')}
                            </p>
                            {props?.match === 'a' ?
                                <a href={process.env.REACT_APP_ADMIN_LOGIN_LINK} className={'btn btn-primary'}>
                                    {i18n.t('goto-login')}
                                </a> :
                                <Link to={'/login'} className={'btn btn-primary'}> {i18n.t('goto-login')}</Link>}
                        </section>
                    </>)}
                </div>

            </div>
        </div>
    );
}


const mapStateToProps = (state) => ({
  ...state,
  isConfirm: state.auth.isConfirm,
  });

  const mapDispatchToProps = dispatch => ({
    confirmEmail: (expires, token) => dispatch(confirmEmail(expires, token)),
    confirmComplete: () => dispatch({
        type: "EMAIL_CONFIRM",
        payload: false,
      }),
    launchOverlay: (value) =>dispatch({
      type: "OVERLAY_LOADING",
      payload: value,
    }),
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail);