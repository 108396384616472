import { combineReducers } from 'redux';

export function error(state = null, action) {
    switch (action.type) {
        case 'USER_DELETE_ERROR':
            return action.error;

        default:
            return state;
    }
}

export default combineReducers({ error });
