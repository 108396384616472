const Constants = {
    COOKIE_CONSENT_NAME: 'cc_opt',
    /**
     * Logout after LOGOUT_TIME_OUT [seconds] of inactivity
     */
    LOGOUT_TIME_OUT: 3600,
    WARNING_TIME_OUT: 60,

    /**
     * Se flag "Ricordami" durante login:
     * numero di giorni per cui mantere il token nei cookie.
     *
     * NB: deve essere minore o uguale a ttl in server/config/packages/gesdinet_jwt_refresh_token.yaml
     */
    NUMBER_REMEMBERED_DAYS: 7,

    /**
     * Orders state
     */
    PENDING_CONFIRMATION: 'pending_confirmation',
    DECLINED: 'declined',
    ELIMINATED: 'eliminated',
    ACCEPTED: 'accepted',
    EXPIRED: 'expired',
    REJECTED: 'rejected',
    CANCELED: 'canceled',
    IN_PROGRESS: 'in_progress',
    INTERRUPTED: 'interrupted',
    COMPLETED: 'completed',

    /**
     * Review star
     */
    NUMBER_OF_STAR: 5,
};
export default Constants;
