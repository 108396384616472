import React, { useState, useEffect } from "react";
import { login } from "../../redux/actions/auth";
import { Navigate, Link, useNavigate  } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Col,
  Row,
  Form,
  Button,
  InputGroup,
  Container,
  Card
} from "react-bootstrap";

const Login = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const navigate  = useNavigate ();
  const handleSubmit = (e) => {
    e.preventDefault();
    if(username != null && password != null){
    props.launchOverlay(true);
    // Assuming props.login returns a promise
    props.login(username, password, rememberMe)
    .then((result) => {
      // Handle successful login
      console.log("Login successful:", result);
      if(props.redirectPath != null){
          navigate(props.redirectPath);
      } else{
        navigate('/dashboard');
      }
    })
    .catch((error) => {
        // Handle login error
        console.error("Login failed:", error);
        // You can display an error message or handle the error in some other way
    })
    .finally(() => {
        // This block will be executed regardless of success or failure
        props.launchOverlay(false); // Assuming this stops the overlay
    });
    }
  };

  useEffect(() => {
// what

 
  }, [props]);



   if (props.isLoggedIn) {
      return <Navigate to={"/"} />;
    }


  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center form-bg-image">
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Sign in to our platform</h3>
                </div>
                <Form className="mt-4" onSubmit={handleSubmit}>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        required
                        type="email"
                        placeholder="example@company.com"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>Your Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <Form.Check
                      type="checkbox"
                      id="rememberMe"
                      label="Remember me"
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                    />
                    <Card.Link as={Link} to="/reset-password" className="small text-end">
                      Lost password?
                    </Card.Link>
                  </div>
                  <Button variant="primary" type="submit" className="w-100">
                    Sign in
                  </Button>
                </Form>
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Not registered? {" "}
                    <Card.Link as={Link} to="/register" className="fw-bold">
                      Create account
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};


Login.propTypes = {
  login: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  launchOverlay: PropTypes.func,
};


//export default Login;
const mapStateToProps = (state) => ({
  ...state,
  redirectPath: state.auth.savePath,
  isLoggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = dispatch => ({
   login: (username, password, rememberMe) => dispatch(login(username, password, rememberMe)),

  launchOverlay: (value) =>dispatch({
    type: "OVERLAY_LOADING",
    payload: value,
  }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);