import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logout } from "../../redux/actions/auth";
import { Navigate } from "react-router-dom";

class Logout extends React.Component {
  componentDidMount() {
    this.props.logout();
  }

  render() {
    if (!this.props.isLoggedIn) {
      return <Navigate to={"/login"} />;
    }
  }
}

const mapStateToProps = (state) => ({
  ...state,
  isLoggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

Logout.propTypes = {
  userLogout: PropTypes.func,
  isLoggedIn: PropTypes.bool,
};
export default connect(mapStateToProps, mapDispatchToProps)(Logout);
