import React, { Component } from 'react';
import ContactForm from '../components/contact/ContactForm';
import ContactInfo from '../components/contact/ContactInfo';
import Meta from '../components/layout/Meta';
class Contact extends Component {
    render() {
        return (
            <React.Fragment>
                <Meta title="Contact"/>                 
                <ContactForm />
                <ContactInfo />
            </React.Fragment>
        );
    }
}

export default Contact;