import { ENTRYPOINT } from '../config/entrypoint';
import { SubmissionError } from 'redux-form';
import get from 'lodash/get';
import has from 'lodash/has';
import mapValues from 'lodash/mapValues';
import store from '../redux/store';
import i18n from '../../i18n';

const MIME_TYPE = 'application/ld+json';

export const fetch = (url, options = {}) => {
    if ('undefined' === typeof options.headers)
        options.headers = new Headers();
    if (null === options.headers.get('Accept'))
        options.headers.set('Accept', MIME_TYPE);
    if (
        'undefined' !== options.body &&
        !(options.body instanceof FormData) &&
        null === options.headers.get('Content-Type')
    )
        options.headers.set('Content-Type', MIME_TYPE);
    return global.fetch(new URL(url, ENTRYPOINT), options).then(response => {

        if (response.ok)
            return response;

        //console.log('response', response);

        if (response.status === 500)
            throw new Error(i18n.t('Internal Server Error'));

        return response.json().then(json => {
            const error = json['hydra:description'] || json.message || json.error || response.statusText;
            if (!json.violations)
                throw Error(i18n.t(error));
            const errors = { _error: error };
            json.violations.map(
                violation => (errors[violation.propertyPath] = violation.message),
            );
            throw new SubmissionError(errors);
        });
    }).catch(e => {
        if (e.code === 20) { // abort occurred
            console.log('abort occurred', url);
            return;
        }
        throw e;
    });
};

export const fetchAuth = (url, options = {}) => {

    const AccessToken = store.getState().auth.accessToken;
    if ('undefined' === typeof options.headers)
        options.headers = new Headers();
    if (null === options.headers.get('Accept'))
        options.headers.set('Accept', MIME_TYPE);
    if (
        'undefined' !== options.body &&
        !(options.body instanceof FormData) &&
        null === options.headers.get('Content-Type')
    )
        options.headers.set('Content-Type', MIME_TYPE);

    options.headers.set('Authorization', 'Bearer ' + AccessToken);

    // console.log('fetchAuth', url);

    return global.fetch(new URL(url, ENTRYPOINT), options).then(response => {

        // console.log('response', response);

        if (response.ok)
            return response;

        if (response.status === 401)
            throw new UnauthorizedError(i18n.t('unauthorized-error'));

        return response.json().then(json => {
            const error = json['hydra:description'] || response.statusText;
            if (!json.violations)
                throw Error(i18n.t(error));
            const errors = { _error: error };
            json.violations.map(
                violation => (errors[violation.propertyPath] = violation.message),
            );
            throw new SubmissionError(errors);
        });
    }).catch(e => {
        if (e.code === 20) { // abort occurred
            console.log('abort occurred', url);
        }
        throw e;
    });
};

export function normalize(data) {
    if (has(data, 'hydra:member')) {
        // Normalize items in collections
        data['hydra:member'] = data['hydra:member'].map(item => normalize(item));
        return data;
    }
    // Flatten nested documents
    return mapValues(data, value =>
        Array.isArray(value) ?
            value.map(v => get(v, '@id', v)) :
            get(value, '@id', value),
    );
}


export class UnauthorizedError extends Error {

    constructor(message = 'Unauthorized Exception') {
        super(message);
        this.name = 'UnauthorizedError';
    }

}
