import React from "react";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";

function Operations({ user, isLoggedIn }) {

  if (isLoggedIn) {
    return <Navigate to="/login" />;
  }

  return (
   <div>
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">

        <div className="card-box">
                <h4 className="header-title mb-3">Operations</h4>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Description</th>
                        <th>Assign</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>01/01/2023</td>
                        <td>2500 Fcfa</td>
                        <td>Adminox Admin</td>
                        <td>Audrey</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>01/02/2023</td>
                        <td>2500 Fcfa</td>
                        <td>Adminox Frontend</td>
                        <td>Valentina</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>01/01/2015</td>
                        <td>2500 Fcfa</td>
                        <td>Adminox Admin</td>
                        <td>Paule</td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>01/01/2015</td>
                        <td>2500 Fcfa</td>
                        <td>Adminox Admin</td>
                        <td>Zoe</td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>

      </div>
    </div>
    </div>
    </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
    ...state,
    isConfirm: state.auth.isConfirm,
    });
  
    const mapDispatchToProps = dispatch => ({
      //confirmEmail: (expires, token) => dispatch(confirmEmail(expires, token)),
      launchOverlay: (value) =>dispatch({
        type: "OVERLAY_LOADING",
        payload: value,
      }),
    });
    
    export default connect(mapStateToProps, mapDispatchToProps)(Operations);